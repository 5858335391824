import axios from 'axios';

const domain = 'https://32cdi-schedule.spaceapp.ru';

const metods = () => {
  return {
    // eslint-disable-next-line no-unused-vars
    getDoctors(success = function () {}, _errorCallBack = function () {}) {
      axios.post(`${domain}/send_doctors_v3.php`, {}).then((response) => {
        success(response.data);
        // if (response.data.OperationStatus !== 'Success') {
        //   errorCallBack();
        // } else {
        //   success(response.data);
        // }
      });
    },
    // eslint-disable-next-line no-unused-vars
    getSchedule(personId,filialId, success = function () {}, _errorCallBack = function () {}) {
      axios.post(`${domain}/send_schedule.php`, { doctorId: personId, id_filial: filialId  }).then((response) => {
        success(response.data);
        //console.log(response.data);
        // if (response.data.OperationStatus !== 'Success') {
        //   errorCallBack();
        // } else {
        //   success(response.data);
        // }
      });
    },
    // eslint-disable-next-line no-unused-vars
    sendEmail(person, date, time, name, phone, comment = '', isSeverePain = false, isSimpleRecord = false, success = function () {}, _errorCallBack = function () {}) {
      axios.post(`${domain}/send_email.php`, { person, date, time, name, phone, comment, isSeverePain, isSimpleRecord,  site: 'kpdEjgAQM1tYlD4pgMma' }).then((response) => {
        success(response.data);
        // if (response.data.OperationStatus !== 'Success') {
        //   errorCallBack();
        // } else {
        //   success(response.data);
        // }
      });
    },
    /*
    даты в формате "2022-02-09 09:01"
    */
    sendRequestToMIS(docId, dateTimeStart, dateTimeEnd, phone, firstName, secondName, lastName, birdday, requestComment = null, success = function () {}, _errorCallBack = function () {}) {
      const token = 'c8a32a8f-f653-4dcd-bf00-cb41404b30e9';
      const config = {
        headers: { Authorization: `Token ${token}`, 'Content-Type': 'application/json;charset=utf-8', Accept: '*/*' },
      };
      const obj = {
        doctor: {
          id: docId,
        },
        appointment: {
          dt_start: dateTimeStart,
          dt_end: dateTimeEnd,
          is_online: false,
          comment: requestComment ? requestComment : 'Запись с сайта',
        },
        client: {
          first_name: firstName,
          second_name: secondName,
          last_name: lastName,
          mobile_phone: phone,
          birthday: birdday,
        },
        appointment_source: '32cdi.ru',
      };

      axios
        .post('https://32pearls.ddns.net:4443/pdvoccupyappointment', obj, config)
        .then(
          (response) => {
            success(response);
          },
          (error) => alert(error)
        )
        .catch((err) => {
          console.log(err);
          _errorCallBack();
        });
    },
  };
};
export default { metods };
