<template>
  <div id="app-schedule-32cdi">
    <a v-if="!isOpen && !isMobileScreen" v-on:click="toggleOpen(true)" class="yButton bottom right" href="#" style="z-index: 10000"
      ><div class="yButtonBackground" style=""></div>
      <div class="yButtonWave" style=""></div>
      <div class="yButtonText">Онлайн запись</div>
      <div class="yButtonIcon"></div
    ></a>

    <div v-if="isOpen" class="main-wrapper-32cdi-schedule h-screen overflow-y-auto bg-white shadow-xl pb-4 flex flex-col flex-wrap">
      <div class="bg-blue-32cdi h-16 flex justify-center items-center relative">
        <svg v-on:click="isOpen = false" class="h-10 w-10 text-white absolute right-2 cursor-pointer" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <circle cx="12" cy="12" r="10" />
          <line x1="15" y1="9" x2="9" y2="15" />
          <line x1="9" y1="9" x2="15" y2="15" />
        </svg>
        <div v-if="slide > 0" v-on:click="backStep" class="absolute top-5 left-3 text-xl text-white cursor-pointer">Назад</div>
        <p class="font-bold text-white text-2xl">Онлайн-запись</p>
      </div>
      <div class="relative flex-1">
        <transition name="no-mode-fade" mode="out-in">
          <div v-if="slide === 0" class="absolute w-full overflow-y-auto">
            <p class="text-md text-gray-700  font-bold mt-5 self-start">Выберите  клинику:</p>
            <div v-on:click="setSity('1')" class="w-10/12 m-auto h-32  border-b-2 border-gray-300 flex flex-col justify-center cursor-pointer">
              <p class="text-md text-blue-700  mb-2 border-b font-bold border-blue-700  self-start">Заводской район</p>
              <p class="text-md text-gray-700  uppercase font-bold  self-start">ул.Огородная, д.144/146</p>
            </div>
            <div v-on:click="setSity('2')" class="w-10/12 m-auto h-32 border-b-2 border-gray-300 flex flex-col justify-center cursor-pointer">
              <p class="text-md text-blue-700 mb-2 border-b font-bold  border-blue-700 self-start">п.Юбилейный</p>
              <p class="text-md text-gray-700  font-bold uppercase self-start">ул.Усть-Курдюмская, д.25</p>
            </div>
          </div>
        </transition>

        <transition name="no-mode-fade" mode="out-in">
          <div v-if="slide === 1" class="absolute w-full overflow-y-auto">
            <div v-on:click="setSpecialization(specializationTypes.therapistType)" class="w-10/12 m-auto h-20 border-b-2 border-gray-300 flex flex-col justify-center cursor-pointer">
              <p class="text-md text-gray-700 border-b font-bold border-blue-500 self-start">ТЕРАПЕВТ</p>
            </div>
            <div v-on:click="setSpecialization(specializationTypes.orthopedistType)" class="w-10/12 m-auto h-20 border-b-2 border-gray-300 flex flex-col justify-center cursor-pointer">
              <p class="text-md text-gray-700 border-b font-bold border-blue-500 self-start">ОРТОПЕД</p>
            </div>
            <div v-on:click="setSpecialization(specializationTypes.orthodontistType)" class="w-10/12 m-auto h-20 border-b-2 border-gray-300 flex flex-col justify-center cursor-pointer">
              <p class="text-md text-gray-700 border-b font-bold border-blue-500 self-start">ОРТОДОНТ</p>
            </div>
            <div v-on:click="setSpecialization(specializationTypes.implantologistSurgeonType)" class="w-10/12 m-auto h-20 border-b-2 border-gray-300 flex flex-col justify-center cursor-pointer">
              <p class="text-md text-gray-700 border-b font-bold border-blue-500 self-start">ХИРУРГ - ИМПЛАНТОЛОГ</p>
            </div>
            <div v-on:click="setSpecialization(specializationTypes.pediatricDentistType)" class="w-10/12 m-auto h-20 border-b-2 border-gray-300 flex flex-col justify-center cursor-pointer">
              <p class="text-md text-gray-700 border-b font-bold border-blue-500 self-start">ДЕТСКИЙ СТОМАТОЛОГ</p>
            </div>
            <div v-on:click="setSpecialization(specializationTypes.surgeon)" class="w-10/12 m-auto h-20 border-b-2 border-gray-300 flex flex-col justify-center cursor-pointer">
              <p class="text-md text-gray-700 border-b font-bold border-blue-500 self-start">ХИРУРГ</p>
            </div>

            <div
              @click="
                slide = 7;
                isSeverePain = true;
              "
              class="w-10/12 m-auto h-14 border-b-2 border-gray-300 flex flex-col justify-center cursor-pointer mt-28"
            >
              <p class="text-md text-gray-700 border-b font-bold border-gray-700 self-start">БОЛИТ ЗУБ</p>
            </div>
            <div
              @click="
                slide = 7;
                isSimpleRecord = true;
              "
              class="w-10/12 m-auto h-14 border-b-2 border-gray-300 flex flex-col justify-center cursor-pointer"
            >
              <p class="text-md text-gray-700 border-b font-bold border-gray-700 self-start">НЕ ЗНАЕТЕ К КОМУ ЗАПИСАТЬСЯ</p>
            </div>
          </div>
        </transition>
        <transition name="no-mode-fade" mode="out-in">
          <div v-if="slide === 2" class="pb-20 absolute w-full overflow-y-auto">
            <div v-if="selectedSpecializationType" class="pt-3 pl-2 mb-3">
              <div>Выбранная специализация</div>
              <div class="font-bold">{{ getSpecializationTitle(selectedSpecializationType) }}</div>
            </div>
            <div v-for="person in staffFiltered" :key="person.id" v-on:click="selectPerson(person)" class="w-10/12 m-auto pt-4 pb-4 border-b-2 border-gray-300 flex flex-col items-center justify-center cursor-pointer">
              <p>{{ person.fio }}</p>
            </div>
          </div>
        </transition>
        <transition name="no-mode-fade" mode="out-in">
          <div v-if="slide === 3" class="absolute w-full overflow-y-auto">
            <h2 class="mt-6">Выбор даты</h2>
            <div class="">
              <div v-if="selectedPerson === null" class="text-red-400">Выберите врача!</div>
              <div v-if="selectedPerson !== null" class="w-32 m-auto pt-4 border-b-2 border-gray-300 flex items-center content-start flex-wrap">
                <div v-for="record in sortedSelectedDates" :key="record.id" v-on:click="selectDate(record)" class="px-2 py-1 mb-2 m-auto border-gray-200 border-2 rounded-md cursor-pointer">
                  {{ record.date }}
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition name="no-mode-fade" mode="out-in">
          <div v-if="slide === 4" class="absolute w-full overflow-y-auto">
            <h2 class="mt-6">Выбор времени</h2>
            <div class="w-52 m-auto pt-4 border-b-2 border-gray-300 flex items-center content-start flex-wrap">
              <div v-for="record in getTimeBySelectedDate" :key="record.id" v-on:click="selectTime(record)" class="px-2 py-1 mb-2 m-auto border-gray-200 border-2 rounded-md cursor-pointer">{{ record.time_start }} - {{ record.time_end }}</div>
            </div>
          </div>
        </transition>
        <transition name="no-mode-fade" mode="out-in">
          <div v-if="slide === 5" class="absolute w-full overflow-y-auto">
            <h2 class="font-bold mt-2">Ваш Выбор</h2>
            <div class="w-10/12 py-4 m-auto border-b-2 border-gray-300 flex items-center content-around flex-wrap">
              <div class="w-full px-2 py-1 mr-1 mb-1 border-gray-200 border-2 rounded-md">
                {{ selectedPerson.fio }}
              </div>
              <div class="px-2 py-1 mr-1 mb-1 border-gray-200 border-2 rounded-md">
                {{ selectedDate }}
              </div>
              <div class="px-2 py-1 mr-1 mb-1 border-gray-200 border-2 rounded-md">
                {{ selectedTime }}
              </div>
              <input
                v-model="name"
                v-bind:class="{ 'border-red-500': nameError }"
                class="shadow appearance-none border rounded w-full py-2 px-3 mb-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="username"
                type="text"
                placeholder="Имя"
              />
              <input
                v-model="phone"
                v-bind:class="{ 'border-red-500': phoneError }"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="username"
                type="text"
                placeholder="Телефон"
              />
              <button @click="sendEmail" class="px-3 py-2 ml-auto mr-auto mt-3 bg-green-300">Записаться</button>
            </div>
          </div>
        </transition>
        <transition name="no-mode-fade" mode="out-in">
          <div v-if="slide === 6" class="absolute w-full overflow-y-auto">
            <h2 class="font-bold mt-6">Успешно</h2>
            <div class="w-10/12 py-4 m-auto border-b-2 border-gray-300 flex items-center content-around flex-wrap">
              <div class="px-2 py-1 m-auto mb-1 border-gray-200 border-2 rounded-md">
                <p>Заявка на запись успешно отправлена.</p>
                <p>Для подтверждения записи, ожидайте звонка из клиники.</p>
              </div>
              <button
                v-on:click="
                  slide = 0;
                  isOpen = false;
                "
                class="px-3 py-2 ml-auto mr-auto mt-3 bg-green-300"
              >
                Закрыть
              </button>
            </div>
          </div>
        </transition>
        <transition name="no-mode-fade" mode="out-in">
          <div v-if="slide === 7" class="absolute w-full overflow-y-auto">
            <h2 class="font-bold mt-2">Запись</h2>
            <div class="w-10/12 py-4 m-auto border-b-2 border-gray-300 flex items-center content-around flex-wrap">
              <input
                v-model="name"
                v-bind:class="{ 'border-red-500': nameError }"
                class="shadow appearance-none border rounded w-full py-2 px-3 mb-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="username"
                type="text"
                placeholder="Имя"
              />
              <input
                v-model="phone"
                v-bind:class="{ 'border-red-500': phoneError }"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="username"
                type="text"
                placeholder="Телефон"
              />
              <button @click="sendSimpleRequest" class="px-3 py-2 ml-auto mr-auto mt-3 bg-green-300">Записаться</button>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <mobile-widget :openSchedule="toggleOpen" :isMobileScreen="isMobileScreen" :isWidgetOpen="isOpen" />
  </div>
</template>

<script>
import api from './api/index';
import MobileWidget from './components/mobile-widget.vue';

export default {
  name: 'App',
  components: { MobileWidget },
  data() {
    return {
      isSeverePain: false,
      isSimpleRecord: false,
      width: 0,
      isOpen: false,
      slide: 0,
      selectedPerson: null,
      selectedDate: null,
      selectedTime: null,
      selectedSpecializationType: null,
      timeStart: null,
      timeEnd: null,
      name: '',
      phone: '',
      nameError: false,
      phoneError: false,
      cityFilter: [],
      cityType: '',
      specializationTypes: {
        therapistType: 'therapist',
        orthopedistType: 'orthopedist',
        orthodontistType: 'orthodontist',
        implantologistSurgeonType: 'implantologistSurgeon',
        pediatricDentistType: 'pediatricDentist',
        surgeon: 'surgeon',
      },
      staff: [
        // {
        //   id: 3,
        //   fio: '',
        //   espec: '',
        // },
      ],
      staffFiltered: [],
      schedule: [
        // {
        //   id: 5,
        //   date: '2021-06-30',
        //   doc_id: '',
        //   free: true,
        //   room: '',
        //   time_start: '',
        //   time_end: '',
        // },
      ],
    };
  },
  async mounted() {
    this.width = window.innerWidth;
    api.metods().getDoctors((res) => {
      this.staff = res.sort((a, b) => {
        return a.fio.localeCompare(b.fio);
      });
    });
    const hash = window.location.hash;
    if (hash === '#openSchedule') {
      this.isOpen = true;
    }
  },
  created() {
    window.addEventListener('resize', this.updateWidth);
  },
  computed: {
    uniqueSelectedDates: function () {
      if (this.schedule.length > 0) {
        return this.schedule.filter((value, index, self) => {
          return self.findIndex((v) => v.date === value.date) === index;
        });
      }
      return [];
    },
    sortedSelectedDates: function () {
      if (this.schedule.length > 0) {
        return [...this.uniqueSelectedDates].sort((a, b) => {
          if (this.parseDdmmyyyy(a.date) > this.parseDdmmyyyy(b.date)) {
            return 1;
          } else {
            return -1;
          }
        });
      }
      return [];
    },
    getTimeBySelectedDate: function () {
      return [...this.schedule.filter((item) => item.date === this.selectedDate)].sort((a, b) => {
        let first = a.time_start.split(':')[0];
        let second = b.time_start.split(':')[0];
        if (first > second) {
          return 1;
        } else {
          return -1;
        }
      });
    },
    isMobileScreen() {
      return this.width < 768;
    },
  },
  methods: {
    backStep() {
      if (this.slide > 0) {
        if (this.slide === 7) {
          this.slide = 0;
        } else {
          this.slide--;
        }
      }
    },
    toggleOpen(isOpen) {
      this.isOpen = isOpen;
    },
    updateWidth() {
      this.width = window.innerWidth;
    },
    parseDdmmyyyy(str) {
      var parts = str.split('.');
      return new Date(parts[2], parts[1] - 1, parts[0]);
    },
    formatDateToYyyyMmDd(str) {
      var parts = str.split('.');
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    },
    selectPerson(person) {
      this.selectedPerson = person;
      this.slide = 3;
      api.metods().getSchedule(person.id, this.cityType, (res) => {
        this.schedule = res.filter((item) => item.free === 'true');
      });
    },
    selectDate(scheduleObj) {
      this.selectedDate = scheduleObj.date;
      this.slide = 4;
    },
    selectTime(scheduleObj) {
      this.selectedTime = `${scheduleObj.time_start} - ${scheduleObj.time_end}`;
      this.timeStart = scheduleObj.time_start;
      this.timeEnd = scheduleObj.time_end;
      this.slide = 5;
    },
    sendSimpleRequest() {
      if (this.name === '') {
        this.nameError = true;
      } else {
        this.nameError = false;
      }
      if (this.phone === '') {
        this.phoneError = true;
      } else {
        this.phoneError = false;
      }
      if (!(this.phoneError || this.nameError)) {
        const clientPerson = this.getFullName(this.name);

        api.metods().sendRequestToMIS(
          '',
          '',
          '',
          this.phone,
          clientPerson.firstName,
          clientPerson.secondName,
          clientPerson.lastName,
          '',
          this.isSeverePain ? 'Острая боль' : null,
          this.isSeverePain,
          this.isSimpleRecord,
          () => {
            // this.selectedPerson = null;
            // this.selectedDate = null;
            // this.selectedTime = null;
            // this.timeStart = null;
            // this.timeEnd = null;
            // this.schedule = [];
            // this.slide = 5;
            // this.name = '';
            // this.phone = '';
          },
          () => {
            alert('Ошибка, попробуйте позже!');
          }
        );

        api.metods().sendEmail(
          '',
          '',
          '',
          this.name,
          this.phone,
          this.isSeverePain ? 'Острая боль' : '',
          this.isSeverePain,
          this.isSimpleRecord,
          () => {
            this.slide = 6;
            this.resetData();
            /* eslint-disable-next-line */
            ym(38599790, 'reachGoal', 'onlajn_zapis');
            /* eslint-disable-next-line */
            gtag('event', 'onlajn_zapis', { event_category: 'onlajn_zapis', event_action: 'clik' });
          },
          () => {
            alert('Ошибка, попробуйте позже!');
          }
        );
      }
    },
    sendEmail() {
      if (this.name === '') {
        this.nameError = true;
      } else {
        this.nameError = false;
      }
      if (this.phone === '') {
        this.phoneError = true;
      } else {
        this.phoneError = false;
      }
      if (!(this.phoneError || this.nameError)) {
        const dateTimeStart = `${this.formatDateToYyyyMmDd(this.selectedDate)} ${this.timeStart}`;
        const dateTimeEnd = `${this.formatDateToYyyyMmDd(this.selectedDate)} ${this.timeEnd}`;
        const clientPerson = this.getFullName(this.name);

        api.metods().sendRequestToMIS(
          this.selectedPerson.id,
          dateTimeStart,
          dateTimeEnd,
          this.phone,
          clientPerson.firstName,
          clientPerson.secondName,
          clientPerson.lastName,
          '',
          null,
          () => {},
          () => {
            alert('Ошибка, попробуйте позже!');
          }
        );

        api.metods().sendEmail(
          this.selectedPerson.fio,
          this.selectedDate,
          this.selectedTime,
          this.name,
          this.phone,
          '',
          false,
          false,
          () => {
            this.slide = 6;
            this.resetData();
            /* eslint-disable-next-line */
            ym(38599790, 'reachGoal', 'onlajn_zapis');
            /* eslint-disable-next-line */
            gtag('event', 'onlajn_zapis', { event_category: 'onlajn_zapis', event_action: 'clik' });
          },
          () => {
            alert('Ошибка, попробуйте позже!');
          }
        );
      }
    },
    resetData() {
      this.selectedPerson = null;
      this.selectedDate = null;
      this.selectedTime = null;
      this.timeStart = null;
      this.timeEnd = null;
      this.schedule = [];
      this.name = '';
      this.phone = '';
      this.isSeverePain = false;
      this.isSimpleRecord = false;
    },
    setSpecialization(specializationType) {
      this.selectedSpecializationType = specializationType;
      this.cityFilter = this.staff.filter((city)=> city.id_filial === this.cityType )
      this.staffFiltered = this.cityFilter
        .filter((item) => item.specializationType === specializationType)
        .sort((a, b) => {
          return a.fio.localeCompare(b.fio);
        });
      this.slide = 2;
    },
    setSity(value) {
      this.cityType = value
      this.slide = 1;
    },
    getSpecializationTitle(specializationType) {
      let title = '';
      switch (specializationType) {
        case this.specializationTypes.therapistType:
          title = 'Терапевт';
          break;
        case this.specializationTypes.orthopedistType:
          title = 'Ортопед';
          break;
        case this.specializationTypes.orthodontistType:
          title = 'Ортодонт';
          break;
        case this.specializationTypes.implantologistSurgeonType:
          title = 'Хирург-имплантолог';
          break;
        case this.specializationTypes.pediatricDentistType:
          title = 'Детский-стоматолог';
          break;
        case this.specializationTypes.surgeon:
          title = 'Хирург';
          break;
      }
      return title;
    },
    getFullName(fullName) {
      const partNames = fullName.split(' ');
      return {
        lastName: partNames[0],
        firstName: partNames[1] ?? '',
        secondName: partNames[2] ?? '',
      };
    },
  },
};
</script>
<style>
/* html {
  font-size: 12px;
} */
</style>
<style lang="scss" scoped>
.main-wrapper-32cdi-schedule {
  width: 400px;
  max-width: 100%;
  overflow: hidden;
}
.bg-blue-32cdi {
  background: #002da3;
}
@-webkit-keyframes wave-stroke {
  100% {
    width: 200%;
    height: 200%;
    border-color: transparent;
    opacity: 0;
  }
}
@-moz-keyframes wave-stroke {
  100% {
    width: 200%;
    height: 200%;
    border-color: transparent;
    opacity: 0;
  }
}
@-o-keyframes wave-stroke {
  100% {
    width: 200%;
    height: 200%;
    border-color: transparent;
    opacity: 0;
  }
}
@keyframes wave-stroke {
  100% {
    width: 200%;
    height: 200%;
    border-color: transparent;
    opacity: 0;
  }
}
.yButton.right {
  right: 60px !important;
}
.yButton.bottom {
  bottom: 170px !important;
}
@media screen and (max-width: 600px) {
  .yButton.right {
    right: 30px !important;
  }
  .yButton.bottom {
    bottom: 100px !important;
  }
}
.yButton {
  position: fixed !important;
  width: 100px !important;
  height: 100px !important;
  box-sizing: border-box !important;
}
.yButtonBackground {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 100px !important;
  height: 100px !important;
  border-radius: 100% !important;
  background-color: #002da3;
  opacity: 0.8 !important;
  box-sizing: border-box !important;
}
.yButtonWave {
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -moz-transform: translate(-50%, -50%) !important;
  -o-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  border-radius: 50% !important;
  border-color: #002da3;
  color: #002da3;
  border: 2px solid !important;
  width: 105%;
  height: 105%;
  opacity: 0.9 !important;
  box-sizing: border-box !important;
  -webkit-animation: wave-stroke 2s infinite cubic-bezier(0.37, 0, 0.8, 0.77);
  -moz-animation: wave-stroke 2s infinite cubic-bezier(0.37, 0, 0.8, 0.77);
  -o-animation: wave-stroke 2s infinite cubic-bezier(0.37, 0, 0.8, 0.77);
  animation: wave-stroke 2s infinite cubic-bezier(0.37, 0, 0.8, 0.77);
}
.yButtonText {
  position: absolute !important;
  top: 30px !important;
  left: 10px !important;
  right: 5px !important;
  border-radius: 50% !important;
  width: 80px !important;
  height: 50px !important;
  color: #fff !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  text-align: center !important;
  vertical-align: middle !important;
  letter-spacing: 1.5px !important;
  font-family: Play, sans-serif !important;
  box-sizing: border-box !important;
}
.yButtonIcon {
  display: none;
  box-sizing: border-box !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}

.component-fade-enter-from,
.component-fade-leave-to {
  opacity: 0;
}

.no-mode-fade-enter-active,
.no-mode-fade-leave-active {
  transition: opacity 0.5s;
}

.no-mode-fade-enter-from,
.no-mode-fade-leave-to {
  opacity: 0;
}
</style>

<style scoped>
@tailwind base;

@tailwind components;

@tailwind utilities;
</style>
