<template>
  <div v-if="isMobileScreen">
    <div v-show="isMapOpen" class="w-screen h-screen">
      <yandex-map style="height: 100%; width:100%" :settings="settings" :coords="coords" :zoom="11" @map-was-initialized="mapCreated"> </yandex-map>
    </div>
    <div class="fixed bottom-0 left-0 w-full">
      <transition name="slide-fade">
        <div class="absolute w-full bottom-0 left-0" v-show="isBarShow">
          <button @click="isBarShow = !isBarShow" class="pt-3 pl-4 pr-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 m-auto text-blue-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
            </svg>
            <div class="w-14 h-1 bg-blue-500"></div>
          </button>

          <section id="bottom-navigation" class="block inset-x-0 bottom-0 border-t-2 border-l-2 border-r-2 border-white rounded-t-lg z-10 bg-white shadow">
            <div id="tabs" class="flex justify-between text-white">
              <div @click="openMap()" class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1 blue-button-32cdi">
                <svg width="25" height="25" viewBox="0 0 42 42" class="inline-block mb-1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path
                      d="M20.5890101,0.254646884 C12.8696785,5.50211755 8.0025785,14.258415 14.1941217,18.8708225 C23.16683,25.5550669 13.3362326,40.2698884 33.1021758,38.4149164 C29.6814884,40.8311956 25.5065164,42.2507054 21,42.2507054 C9.40202025,42.2507054 0,32.8486852 0,21.2507054 C0,9.79003409 9.18071714,0.473634138 20.5890101,0.254646884 Z"
                      fill="currentColor"
                      opacity="0.1"
                    ></path>
                    <path
                      d="M25.9500282,20.3643496 L22.4308312,38.2677802 C22.3775703,38.5387376 22.1147395,38.7152155 21.8437821,38.6619546 C21.6570955,38.6252584 21.507413,38.4857901 21.4576354,38.3021581 L16.5951895,20.3643496 L20.099732,4.44663907 C20.1385204,4.27046145 20.2692032,4.12883813 20.4417012,4.07604096 C20.7057521,3.99522179 20.9853245,4.14376046 21.0661436,4.40781135 L25.9500282,20.3643496 Z M21.3022963,22.2852638 C22.4068658,22.2852638 23.3022963,21.3898333 23.3022963,20.2852638 C23.3022963,19.1806943 22.4068658,18.2852638 21.3022963,18.2852638 C20.1977268,18.2852638 19.3022963,19.1806943 19.3022963,20.2852638 C19.3022963,21.3898333 20.1977268,22.2852638 21.3022963,22.2852638 Z"
                      fill="currentColor"
                      transform="translate(21.272609, 20.629524) rotate(-315.000000) translate(-21.272609, -20.629524) "
                    ></path>
                    <circle stroke="currentColor" stroke-width="2" cx="21" cy="21" r="20"></circle>
                  </g>
                </svg>
                <span class="tab tab-explore block text-lg">Доехать</span>
              </div>
              <a href="tel:+78452374914" class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1 blue-button-32cdi border-r-2 border-l-2 border-white">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="inline-block mb-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <span class="tab tab-home block text-lg">Позвонить</span>
              </a>
              <div @click="openScheduleHandler" class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1 blue-button-32cdi">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="inline-block mb-1" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                  <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                </svg>
                <span class="tab tab-kategori block text-lg">Записаться</span>
              </div>
            </div>
          </section>
        </div>
      </transition>
      <transition name="slide-fade">
        <div class="absolute w-full bottom-0 left-0" v-show="!isBarShow">
          <button @click="isBarShow = !isBarShow" class="pt-3 pl-4 pr-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 m-auto text-blue-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
            </svg>
            <div class="w-14 h-1 bg-blue-500"></div>
          </button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps';

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { yandexMap, ymapMarker },
  props: {
    title: String,
    openSchedule: Function,
    isMobileScreen: Boolean,
    isWidgetOpen: Boolean,
  },
  setup() {},

  data() {
    return {
      isBarShow: true,
      isMapOpen: false,
      settings: {
        apiKey: '263903f1-8a1b-482f-b954-4187d110cf36',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1',
      },
      coords: [51.507407, 45.936466],
    };
  },
  methods: {
    mapCreated: function ($map) {
      this.map = $map;
      // eslint-disable-next-line no-undef
      // this.mapObjectManager = new ymaps.ObjectManager({
      //   clusterize: false,
      //   gridSize: 60,
      //   clusterMinClusterSize: 5,
      //   clusterHasBalloon: true, // Опции кластеров задаются с префиксом cluster.
      //   geoObjectOpenBalloonOnClick: false, // Опции геообъектов задаются с префиксом geoObject
      // });

      //this.map.behaviors.disable('drag');

      // set ObjectManager events
      // this.map.events.add(['click'], function () {
      //   this.mapObjectManager.objects.balloon.close();
      // });
      // Создадим панель маршрутизации.
      // eslint-disable-next-line no-undef
      var routePanelControl = new ymaps.control.RoutePanel({
        options: {
          // Добавим заголовок панели.
          showHeader: true,
          allowSwitch: false,
          // Зададим текст заголовка панели.
          reverseGeocoding: true,
          // title: 'Вызов такси',
          // Пользователь сможет построить только маршрут на такси.
          routePanelTypes: { auto: true, masstransit: true, pedestrian: true, taxi: true },
          // Зададим ширину панели.
          maxWidth: '210px',
        },
      });
      // Зададим тип маршрутизации по умолчанию.
      routePanelControl.routePanel.state.set({
        // Зададим тип маршрутизации - такси.
        type: 'masstransit',
        // Зададим адрес пункта назначения.
        to: [51.507407, 45.936466],
        // Отключим возможность задавать пункт отправления в поле ввода.
        toEnabled: false,
      });
      // eslint-disable-next-line no-undef
      this.map.controls.add(routePanelControl);
    },
    openMap() {
      if (!this.isMapOpen) {
        this.openSchedule(false);
      }
      this.isMapOpen = !this.isMapOpen;
    },
    openScheduleHandler() {
      this.isMapOpen = false;
      this.openSchedule(!this.isWidgetOpen);
    },
  },
  computed: {},
};
</script>

<style scoped>
@tailwind base;

@tailwind components;

@tailwind utilities;

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

.blue-button-32cdi {
  background-image: -webkit-linear-gradient(180deg, #14c1de 0%, #1d5cf1 100%);
}
</style>
